import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({ pages, page, isAdmin = false, keyword = "" }) => {
  return (
    <div className="d-flex justify-content-center my-2 ">
      {pages > 1 && (
        <Pagination>
          {[...Array(pages).keys()].map((item) => (
            <LinkContainer
              key={item + 1}
              to={
                !isAdmin
                  ? keyword
                    ? `/search/${keyword}/page/${item + 1}`
                    : `/page/${item + 1}`
                  : `/admin/productlist/${item + 1}`
              }
            >
              <Pagination.Item active={item + 1 === page}>
                {item + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        </Pagination>
      )}
    </div>
  );
};

export default Paginate;
